html {
  scroll-behavior: smooth;
}

/* Mastery theme styles */
.mastery {
  --primary: #00a4bd;
  --primary-dark: #008299;
  --gradient-start: #e6f7f9;
  --gradient-mid: #d1f0f4;
  --gradient-end: #ffffff;
  --primary-alt: #ffa116;
}

/* Override specific button shadows for teal */
.mastery .btn-primary,
.mastery .nav-cta.button {
  box-shadow: 0 4px 15px rgba(0, 164, 189, 0.2);
}

.mastery .btn-primary:hover,
.mastery .nav-cta.button:hover {
  box-shadow: 0 6px 20px rgba(0, 164, 189, 0.3);
}

/* Override testimonial stars color */
.mastery .testimonial-stars {
  color: var(--primary);
}

/* Override floating icons color */
/* .mastery .floating-icon {
  color: #ffa116;
} */

/* Override FAQ icon color */
.mastery .faq-icon {
  color: var(--primary);
}

/* Override swiper navigation buttons */
.mastery .swiper-button-next,
.mastery .swiper-button-prev {
  color: var(--primary) !important;
}

.mastery .swiper-pagination-bullet {
  background: var(--primary) !important;
}

.mastery .swiper-pagination-bullet-active {
  background: var(--primary) !important;
}

/* Override hero title gradient */
.mastery .hero-title {
  background: linear-gradient(135deg, var(--primary), var(--primary-dark));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.mastery .hero-title .highlight {
  background: none;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: #ffa116;
}

/* Override social links hover color */
.mastery .social-link:hover,
.mastery .footer-cta:hover,
.mastery .footer-links a:hover {
  color: var(--primary);
}

.mastery .mobile-menu.open {
  display: flex;
}

.mastery-logo {
  max-width: 190px;
  margin-bottom: -3px;
}

.hero-laptop {
  max-width: 750px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-laptop-image {
  max-width: 100%;
  height: auto;
  width: 100%;
  object-fit: contain;
}

.hero-mastery {
  padding: 8rem 3rem 2rem;
}

.hero-mastery-container {
  max-width: 1400px;
  width: 100%;
  gap: 2rem;
}

@media (max-width: 1200px) {
  .hero-laptop {
    max-width: 600px;
  }

  .hero-mastery-container {
    gap: 1rem;
  }

  .hero-content {
    min-width: 45%;
  }
}

@media (max-width: 968px) {
  .hero-laptop {
    max-width: 500px;
    margin: 0 auto;
  }

  .hero-laptop-image {
    max-width: 100%;
  }

  .hero-mastery {
    padding: 6rem 1rem 2rem;
  }
}

@media (max-width: 640px) {
  .hero-laptop {
    max-width: 340px;
  }

  .hero-mastery {
    padding: 5rem 1rem 2rem;
  }
}

.comprehensive-features {
  padding: 6rem 2rem;
  text-align: center;
  position: relative;
  background: linear-gradient(
    180deg,
    transparent 0%,
    var(--gradient-start) 10%,
    var(--gradient-start) 90%,
    transparent 100%
  );
}

.comprehensive-features::before,
.comprehensive-features::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 100px;
  pointer-events: none;
}

.comprehensive-features::before {
  top: 0;
  background: linear-gradient(180deg, white 0%, transparent 100%);
}

.comprehensive-features::after {
  bottom: 0;
  background: linear-gradient(0deg, white 0%, transparent 100%);
}

.comprehensive-features h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  font-weight: 700;
  color: var(--text);
}

.comprehensive-features .subtitle {
  font-size: clamp(1.1rem, 2vw, 1.3rem);
  color: var(--primary);
  margin-bottom: 3rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.features-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.feature-item {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 2rem;
  text-align: left;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.feature-item .icon {
  color: var(--primary);
  width: 2rem;
  height: 2rem;
  margin-bottom: 1rem;
}

.feature-item h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--text);
}

.feature-item p {
  color: var(--text-light);
  font-size: 1rem;
  line-height: 1.6;
}

@media (max-width: 1024px) {
  .features-grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 640px) {
  .features-grid-container {
    grid-template-columns: 1fr;
  }

  .comprehensive-features {
    padding: 4rem 1rem;
  }
}

/* Why Now Section */
.why-now {
  padding: 6rem 2rem;
  text-align: center;
}

.why-now h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  font-weight: 700;
  color: var(--text);
}

.why-now .subtitle {
  font-size: clamp(1.1rem, 2vw, 1.3rem);
  color: var(--primary);
  margin-bottom: 3rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.why-now .feature-card {
  text-align: center;
  padding: 2.5rem 2rem;
}

.why-now .feature-card .stat {
  font-size: 3.5rem;
  font-weight: 800;
  color: var(--primary);
  margin-bottom: 1rem;
  line-height: 1;
}

.why-now .feature-card p {
  font-size: 1.1rem;
  line-height: 1.5;
}

.trusted-by {
  max-width: 1200px;
  margin: 4rem auto 0;
  padding: 0 1rem;
}

.trusted-by h3 {
  font-size: 1.5rem;
  color: var(--text);
  margin-bottom: 3rem;
}

.company-logos {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 3rem;
  align-items: center;
  justify-items: center;
}

.company-logos img {
  max-width: 120px;
  height: auto;
  opacity: 0.9;
  transition: all 0.3s ease;
}

.company-logos img[alt="ABSA"] {
  max-width: 60px;
}

.company-logos img[alt="Harper Collins"] {
  max-width: 200px;
}

.company-logos img[alt="Dotsure"] {
  max-width: 140px;
}

.company-logos img:hover {
  opacity: 1;
  transform: translateY(-2px);
}

@media (max-width: 1024px) {
  .company-logos {
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }
}

@media (max-width: 640px) {
  .why-now {
    padding: 4rem 1rem;
  }

  .why-now .feature-card .stat {
    font-size: 3rem;
  }

  .company-logos {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }
}

.mastery .features {
  padding: 6rem 2rem;
  text-align: center;
}

.mastery .features h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  font-weight: 700;
  color: var(--text);
}

.mastery .features .subtitle {
  font-size: clamp(1.1rem, 2vw, 1.3rem);
  color: var(--primary);
  margin-bottom: 4rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.mastery .features-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

@media (max-width: 1024px) {
  .mastery .features-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }
}

@media (max-width: 640px) {
  .mastery .features {
    padding: 4rem 1rem;
  }

  .mastery .features-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .mastery .features h2 {
    font-size: 2rem;
  }

  .mastery .subtitle {
    margin-bottom: 2rem;
  }
}

/* How It Works Section */
.how-it-works {
  padding: 6rem 2rem;
  text-align: center;
}

.how-it-works h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  font-weight: 700;
  color: var(--text);
}

.how-it-works .subtitle {
  font-size: clamp(1.1rem, 2vw, 1.3rem);
  color: var(--primary);
  margin-bottom: 2rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 640px) {
  .features,
  .how-it-works {
    padding: 4rem 1rem;
  }
}

.company-logos-desktop {
  display: block;
}

.company-logos-mobile {
  display: none;
}

.company-logos-carousel {
  padding: 1rem;
}

.company-logos-carousel .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  padding: 1rem;
}

.company-logos-carousel .swiper-slide img {
  max-width: 80%;
  height: auto;
  object-fit: contain;
}

.company-logos-carousel .swiper-slide img[alt="ABSA"] {
  max-width: 90px;
}

.company-logos-carousel .swiper-slide img[alt="Harper Collins"] {
  max-width: 240px;
}

.company-logos-carousel .swiper-slide img[alt="Dotsure"] {
  max-width: 200px;
}

.company-logos-carousel .swiper-button-next,
.company-logos-carousel .swiper-button-prev {
  display: none;
}

@media (max-width: 1024px) {
  .company-logos-desktop {
    display: none;
  }

  .company-logos-mobile {
    display: block;
    max-width: 500px;
    margin: 0 auto;
  }
}

/* Pricing Section */
.pricing-section {
  padding: 6rem 2rem;
  text-align: center;
}

.pricing-section h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-weight: 700;
  color: var(--text);
}

.pricing-section .subtitle {
  font-size: clamp(1.1rem, 2vw, 1.3rem);
  color: var(--primary);
  margin-bottom: 2rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

/* Billing Toggle */
.billing-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.billing-toggle .mobile-text {
  display: none;
}

.billing-toggle .desktop-text {
  display: inline;
}

@media (max-width: 768px) {
  .billing-toggle .mobile-text {
    display: inline;
  }

  .billing-toggle .desktop-text {
    display: none;
  }
}

.billing-toggle span {
  font-size: 1rem;
  color: var(--text-light);
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: default;
}

.billing-toggle span.active {
  color: var(--text);
}

.save-badge {
  margin-left: 0.5rem;
  padding: 0.25rem 0.5rem;
  background-color: #e6f7f9;
  color: var(--primary) !important;
  border-radius: 12px;
  font-size: 0.875rem;
  font-weight: 500;
  white-space: nowrap;
  cursor: default;
}

.toggle-switch {
  position: relative;
  min-width: 48px;
  width: 48px;
  height: 24px;
  background-color: var(--primary);
  border-radius: 24px;
  border: none;
  cursor: pointer;
  padding: 0;
  transition: background-color 0.3s ease;
}

.toggle-slider {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.toggle-switch.active .toggle-slider {
  transform: translateX(24px);
  cursor: pointer;
}

/* Pricing Grid */
.pricing-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(auto, 400px));
  gap: 2rem;
  max-width: 900px;
  margin: 0 auto;
  padding: 0 1rem;
  justify-content: center;
}

.pricing-card {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 2rem;
  text-align: left;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.pricing-header h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: var(--text);
}

.pricing-header p {
  color: var(--text-light);
  margin-bottom: 1.5rem;
}

.price {
  margin-bottom: 1rem;
  display: flex;
  align-items: baseline;
  gap: 0.25rem;
}

.price .currency {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--text);
}

.price .amount {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--text);
  line-height: 1;
}

.price .period {
  color: var(--text-light);
  margin-left: 0.5rem;
}

.annual-price {
  color: var(--text-light);
  font-size: 0.875rem;
  margin-bottom: 1.5rem;
}

.pricing-features {
  flex-grow: 1;
}

.pricing-features p {
  color: var(--text-light);
  margin-bottom: 1rem;
}

.pricing-features ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.pricing-features li {
  color: var(--text);
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.pricing-features li::before {
  content: "✓";
  color: var(--primary);
  font-weight: bold;
}

.pricing-cta {
  display: inline-block;
  width: 100%;
  padding: 0.75rem;
  background-color: var(--primary);
  color: white;
  text-align: center;
  border-radius: 8px;
  text-decoration: none;
  font-weight: 500;
  margin-top: 2rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
  z-index: 2;
  border: none;
  outline: none;
  font-size: inherit;
  font-family: inherit;
}

.pricing-cta:hover {
  background-color: var(--primary-dark);
  transform: translateY(-2px);
  cursor: pointer;
}

@media (max-width: 768px) {
  .pricing-section {
    padding: 4rem 1rem;
  }

  .pricing-section h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .pricing-section .subtitle {
    font-size: 1rem;
    margin-bottom: 2rem;
  }

  .billing-toggle {
    gap: 0.75rem;
    margin-bottom: 2rem;
  }

  .billing-toggle span {
    font-size: 0.875rem;
  }

  .save-badge {
    font-size: 0.75rem;
    padding: 0.2rem 0.4rem;
  }

  .toggle-switch {
    min-width: 44px;
    width: 44px;
    height: 22px;
  }

  .toggle-slider {
    width: 18px;
    height: 18px;
  }

  .toggle-switch.active .toggle-slider {
    transform: translateX(22px);
  }

  .pricing-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    padding: 0 1rem;
  }

  .pricing-card {
    padding: 1.5rem;
  }

  .pricing-header h3 {
    font-size: 1.25rem;
  }

  .pricing-header p {
    font-size: 0.875rem;
  }

  .price {
    margin-bottom: 0.75rem;
  }

  .price .currency {
    font-size: 1.25rem;
  }

  .price .amount {
    font-size: 2rem;
  }

  .price .period {
    font-size: 0.875rem;
  }

  .annual-price {
    font-size: 0.75rem;
    margin-bottom: 1rem;
  }

  .pricing-features p {
    font-size: 0.875rem;
  }

  .pricing-features li {
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }

  .pricing-cta {
    margin-top: 1.5rem;
    padding: 0.6rem;
    font-size: 0.875rem;
  }
}

.hero-cta .btn-primary {
  min-width: 160px;
  padding: 0.75rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Dual Focus Section */
.dual-focus {
  padding: 6rem 2rem;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.dual-focus h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  font-weight: 700;
  color: var(--text);
}

.alt-heading-color {
  color: var(--primary-alt);
}

.dual-focus .subtitle {
  font-size: clamp(1.1rem, 2vw, 1.3rem);
  color: var(--primary);
  margin-bottom: 3rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.dual-focus-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
  max-width: 1200px;
  margin: 0 auto;
}

.dual-focus-card {
  background: white;
  padding: 3rem;
  border-radius: 24px;
  text-align: left;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.dual-focus-card h3 {
  font-size: 1.8rem;
  color: var(--primary-alt);
  display: flex;
  align-items: center;
  gap: 1rem;
}

.dual-focus-card h3 .icon {
  color: var(--primary-alt);
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
}

.dual-focus-card .feature {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
}

.dual-focus-card .feature .icon {
  color: var(--primary);
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
  margin-top: 0.2rem;
}

.dual-focus-card .feature-content h4 {
  font-size: 1.2rem;
  color: var(--text);
  margin-bottom: 0.5rem;
}

.dual-focus-card .feature-content p {
  color: var(--text-light);
  line-height: 1.6;
}

@media (max-width: 1024px) {
  .dual-focus {
    padding: 4rem 2rem;
  }

  .dual-focus-grid {
    gap: 2rem;
  }

  .dual-focus-card {
    padding: 2rem;
  }
}

@media (max-width: 768px) {
  .dual-focus-grid {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .dual-focus-card h3 {
    font-size: 1.6rem;
  }

  .dual-focus {
    padding: 4rem 1rem;
  }
}
