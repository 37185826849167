/* Basic Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: default;
  -webkit-tap-highlight-color: transparent;
}

/* CSS Variables */
:root {
  --primary: #ffa116;
  --primary-dark: #e85a2c;
  --secondary: #f8f9fa;
  --text: #2d3748;
  --text-light: #718096;
  --background: #ffffff;
  --gradient-start: #fff1e6;
  --gradient-mid: #ffe4d4;
  --gradient-end: #ffffff;
}

/* Global Styles */
.subtitle {
  font-size: clamp(1.1rem, 2vw, 1.3rem);
  color: var(--primary);
  margin-bottom: 4rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  position: relative;
  z-index: 2;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, sans-serif;
  color: var(--text);
  background: var(--background);
  line-height: 1.6;
  transition: background 0.3s, color 0.3s;
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 2rem;
  background: transparent;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  position: fixed;
  width: 100%;
  z-index: 10;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.navbar.menu-open {
  background: white;
  backdrop-filter: none;
  -webkit-backdrop-filter: none;
}

.navbar a {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.desktop-nav {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.logo {
  max-width: 120px;
  display: block;
}

.nav-cta {
  color: var(--primary);
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 600;
  transition: color 0.3s ease;
}

.nav-cta:hover {
  color: var(--primary-dark);
}

.nav-cta.button {
  background-color: var(--primary);
  color: white;
  padding: 0.3rem 1.25rem;
  border-radius: 8px;
  font-size: 1.1rem;
  box-shadow: 0 4px 15px rgba(255, 107, 53, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.nav-cta.button:hover {
  color: white;
  transform: translateY(-1px);
  box-shadow: 0 6px 20px rgba(255, 107, 53, 0.3);
}

.nav-cta.ai-button {
  color: #435ce8;
  font-weight: 600;
}

.nav-cta.ai-button:hover {
  color: #3a4ec7;
}

/* Main */
main {
  position: relative;
  flex: 1;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
}

/* Floating Icons */
.floating-icons-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
  overflow: visible;
}

.floating-icon {
  position: absolute;
  width: 48px;
  height: 48px;
  color: #00a4bd;
  opacity: 0.3;
  pointer-events: none;
  will-change: transform;
}

/* Hero Section */
.hero {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8rem 3rem 2rem;
  background: linear-gradient(
    to bottom,
    var(--gradient-start) 0%,
    var(--gradient-end) 85%,
    #ffffff 100%
  );
  position: relative;
  overflow: visible;
}

.hero-container {
  max-width: 1200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  position: relative;
  z-index: 3;
}

.hero-content {
  flex: 1;
  text-align: left;
  max-width: 700px;
  justify-content: center;
  align-items: center;
}

.hero-phone {
  flex: 1;
  max-width: 380px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.hero-phone img {
  width: 100%;
  height: auto;
  display: block;
  background: transparent;
  box-shadow: none;
}

.hero-title {
  font-size: clamp(2rem, 4vw, 3.5rem);
  font-weight: 800;
  line-height: 1.4;
  margin-bottom: 1.5rem;
  background: linear-gradient(135deg, var(--primary), var(--primary-dark));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: left;
  position: relative;
  z-index: 2;
}

.hero-subtitle {
  font-size: clamp(1.1rem, 2vw, 1.3rem);
  color: var(--text-light);
  margin-bottom: 2.5rem;
  max-width: 500px;
  text-align: left;
  position: relative;
  z-index: 2;
}

.hero-cta {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
}

.hero-cta .btn-primary {
  min-width: 160px;
  padding: 0.75rem 1.5rem;
}

.hero-cta .app-store-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.hero-cta .store-button {
  height: 40px;
  transition: transform 0.3s ease;
}

.hero-cta .store-button:hover {
  transform: translateY(-2px);
}

.hero-cta .store-button img {
  height: 100%;
  width: auto;
  display: block;
}

@media (max-width: 968px) {
  .hero-container {
    flex-direction: column;
    gap: 3rem;
    margin-top: 2rem;
  }

  .hero-content {
    text-align: center;
  }

  .hero-title,
  .hero-subtitle {
    text-align: center;
  }

  .hero-subtitle {
    margin-left: auto;
    margin-right: auto;
  }

  .hero-cta {
    align-items: center;
  }

  .hero-phone {
    max-width: 260px;
    margin: 0 auto;
  }

  .hero-cta .btn-primary {
    width: 240px;
  }
}

/* Buttons */
.btn-primary,
.btn-secondary {
  padding: 1rem 2rem;
  border-radius: 15px;
  font-weight: 600;
  font-size: 1.1rem;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-decoration: none;
  display: inline-block;
}

.btn-primary {
  background: var(--primary);
  color: white;
  border: none;
  box-shadow: 0 4px 15px rgba(255, 107, 53, 0.2);
}

.btn-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(255, 107, 53, 0.3);
}

.btn-secondary {
  background: transparent;
  color: var(--primary);
  border: 2px solid var(--primary);
}

.btn-secondary:hover {
  transform: translateY(-2px);
  background: rgba(26, 115, 232, 0.1);
}

/* Features Section */
.app .features {
  padding: 4rem 2rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.app .features h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: var(--text);
  z-index: 2;
}

.app .features-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.app .feature-card {
  background: white;
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 2;
  height: 100%;
}

.app .feature-icon {
  width: 48px;
  height: 48px;
  color: var(--primary);
  margin-bottom: 1.5rem;
}

.app .feature-card h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--text);
  position: relative;
  z-index: 2;
}

.app .feature-card p {
  color: var(--text-light);
}

@media (max-width: 1200px) {
  .app .features-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    padding: 0 1rem;
  }

  .app .feature-card {
    padding: 1.5rem;
  }
}

@media (max-width: 968px) {
  .app .features-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }
}

@media (max-width: 640px) {
  .app .features {
    padding: 4rem 1rem;
  }

  .app .features-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .app .feature-card {
    padding: 1.5rem;
  }

  .app .features h2 {
    font-size: 2rem;
  }
}

/* How It Works Section */
.how-it-works {
  padding: 4rem 2rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.how-it-works h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 3rem;
  color: var(--text);
  z-index: 2;
}

.app-screenshots-carousel {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 1rem;
}

.swiper-slide {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  padding: 2rem;
}

.screenshot-content {
  flex: 1;
  text-align: left;
  max-width: 500px;
}

.screenshot-content h3 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: var(--text);
  position: relative;
  z-index: 2;
}

.screenshot-content p {
  font-size: 1.2rem;
  color: var(--text-light);
  line-height: 1.6;
  position: relative;
  z-index: 2;
}

.screenshot-image {
  flex: 1;
  max-width: 250px;
}

.screenshot-image img {
  width: 100%;
  height: auto;
  display: block;
}

@media (max-width: 768px) {
  .how-it-works {
    padding: 4rem 1rem;
  }

  .how-it-works h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }

  .screenshot-content h3 {
    font-size: 1.75rem;
    margin-bottom: 0.75rem;
  }

  .screenshot-content p {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
  }

  .screenshot-image {
    max-width: 200px;
  }

  .swiper-slide {
    flex-direction: column;
    gap: 2rem;
    padding: 1rem;
  }

  .screenshot-content {
    text-align: center;
    order: -1;
  }
}

/* Testimonials Section */
.testimonials {
  padding: 4rem 2rem;
  background: var(--background);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.testimonials h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: var(--text);
  z-index: 2;
}

.testimonials-desktop {
  display: block;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.testimonials-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 24px;
  width: 100%;
}

.testimonials-mobile {
  display: none;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.testimonials-carousel {
  padding: 1rem;
  height: auto !important;
}

.testimonials-carousel .swiper-slide {
  height: auto !important;
  display: flex;
  justify-content: center;
}

.testimonials-carousel .swiper-wrapper {
  align-items: stretch;
}

.testimonial-card {
  background: white;
  padding: 1.5rem;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 2;
}

.testimonial-stars {
  color: var(--primary);
  display: flex;
  gap: 0.25rem;
  margin-bottom: 1rem;
}

.testimonial-stars svg {
  width: 20px;
  height: 20px;
}

.testimonial-text {
  font-size: 1rem;
  line-height: 1.6;
  color: var(--text);
  margin-bottom: 1rem;
  flex-grow: 1;
}

.testimonial-author h4 {
  font-size: 1rem;
  color: var(--text);
  font-weight: 600;
}

.testimonial-author p {
  color: var(--text-light);
  font-size: 0.9rem;
}

@media (max-width: 1024px) {
  .testimonials-desktop {
    display: none;
  }

  .testimonials-mobile {
    display: block;
  }

  .testimonials-carousel {
    padding: 0 1rem;
  }

  .testimonial-card {
    margin-bottom: 1rem;
  }
}

@media (max-width: 640px) {
  .testimonials-grid {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(8, auto);
  }

  .testimonials {
    padding: 4rem 1rem;
  }

  .testimonials .subtitle {
    margin-bottom: 1rem;
  }
}

/* CTA Section */
.cta-section {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 2rem;
}

.cta-section-div {
  padding: 4rem;
  background: linear-gradient(
    135deg,
    var(--gradient-start),
    var(--gradient-mid)
  );
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  gap: 2rem;
  border-radius: 24px;
}

.cta-content {
  flex: 1;
  text-align: left;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cta-title {
  font-size: clamp(2rem, 4vw, 3.5rem);
  font-weight: 800;
  line-height: 1.2;
  color: var(--text);
  margin-bottom: 3rem;
  position: relative;
  z-index: 2;
}

.app-store-buttons {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  margin-bottom: 3rem;
}

.store-button {
  height: 40px;
  transition: transform 0.3s ease;
  display: inline-block;
}

.store-button:hover {
  transform: translateY(-2px);
}

.store-button img {
  height: 100%;
  width: auto;
  display: block;
}

.cta-image {
  flex: 0 0 auto;
  max-width: 220px;
}

.cta-image img {
  width: 100%;
  height: auto;
  display: block;
}

@media (max-width: 768px) {
  .cta-section {
    padding: 4rem 1rem;
  }

  .cta-section-div {
    flex-direction: column;
    text-align: center;
    padding: 2rem;
    gap: 1rem;
  }

  .cta-content {
    text-align: center;
    align-items: center;
  }

  .cta-title {
    font-size: 2.4rem;
  }

  .app-store-buttons {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  .cta-image {
    max-width: 180px;
  }
}

/* Footer */
footer {
  padding: 3rem 2rem 1rem 2rem;
  /* border-top: 1px solid rgba(0, 0, 0, 0.1); */
  background: linear-gradient(
    to top,
    var(--gradient-start) 0%,
    var(--gradient-end) 85%,
    #ffffff 100%
  );
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 0.75rem;
  position: relative;
  z-index: 1;
}

.footer-content h2 {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--text);
  margin: 0;
  margin-bottom: 0.5rem;
  position: relative;
  z-index: 2;
}

.footer-cta {
  color: var(--text);
  text-decoration: none;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 0.05em;
  transition: color 0.3s ease;
  margin-bottom: 0.5rem;
}

.footer-cta:hover {
  color: var(--primary);
}

.social-links {
  display: flex;
  gap: 1.5rem;
  margin: 0.5rem 0;
}

.social-link {
  color: var(--text);
  transition: color 0.3s ease;
}

.social-link:hover {
  color: var(--primary);
}

.social-link svg {
  width: 24px;
  height: 24px;
}

.footer-logo {
  margin: 0.75rem 0;
}

.footer-logo img {
  height: 24px;
  width: auto;
}

.footer-links {
  display: flex;
  gap: 1.5rem;
  margin: 0;
}

.footer-links a {
  color: var(--text);
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: var(--primary);
}

.copyright {
  color: var(--text-light);
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-cta {
    flex-direction: column;
    align-items: center;
  }

  .btn-primary,
  .btn-secondary {
    width: 100%;
    max-width: 300px;
  }

  .features-grid {
    grid-template-columns: 1fr;
    padding: 0;
  }

  .footer {
    padding: 2rem 1.5rem;
  }

  .footer-content {
    gap: 0.75rem;
  }

  .footer-content h2 {
    font-size: 2rem;
  }

  .footer-links {
    flex-direction: column;
    gap: 0.5rem;
  }

  .testimonial-card {
    min-width: 260px;
    height: auto;
    min-height: 220px;
  }
}

/* Only allow pointer cursor on interactive elements */
.btn-primary,
.btn-secondary,
button,
.nav-cta,
.footer-cta,
.social-link,
.social-link svg,
.social-link path,
.footer-links a,
.footer-logo,
.footer-logo img,
.store-button,
.store-button img,
.faq-icon,
.faq-icon svg {
  cursor: pointer;
}

/* Swiper Customization */
.swiper-button-next,
.swiper-button-prev {
  color: var(--primary) !important;
  width: 40px !important;
  height: 40px !important;
  background: white;
  border-radius: 50%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  outline: none !important;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

.swiper-button-next:focus,
.swiper-button-prev:focus {
  outline: none !important;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  -webkit-tap-highlight-color: transparent;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 1.2rem !important;
  font-weight: bold;
}

.swiper-pagination-bullet {
  background: var(--primary) !important;
  width: 8px !important;
  height: 8px !important;
  opacity: 0.5;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: var(--primary) !important;
}

@media (min-width: 1280px) {
  .testimonial-card {
    min-width: 280px;
    max-width: 340px;
  }
}

@media (min-width: 800px) {
  ::-webkit-scrollbar-track {
    display: none;
  }
  ::-webkit-scrollbar-thumb {
    display: none;
  }
  ::-webkit-scrollbar {
    display: none;
  }
}

.navbar a img.logo,
.footer-logo a img {
  cursor: pointer;
}

.footer-logo a {
  display: flex;
  align-items: center;
  text-decoration: none;
  line-height: 0;
}

/* FAQ Section */
.faq-section {
  padding: 4rem 2rem 8rem;
  background: var(--background);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.faq-section h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 4rem;
  color: var(--text);
  z-index: 2;
}

.faq-container {
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.faq-item {
  background: white;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  z-index: 2;
}

.faq-question {
  width: 100%;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  border: none;
  text-align: left;
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--text);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.faq-question:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.faq-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary);
  margin-left: 1rem;
  transition: transform 0.3s ease;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.faq-icon svg {
  width: 100%;
  height: 100%;
}

.faq-answer {
  padding: 0 1.5rem;
  overflow: hidden;
}

.faq-answer p {
  color: var(--text-light);
  line-height: 1.6;
  margin-bottom: 1rem;
}

.faq-answer p:first-child {
  margin-top: 1rem;
}

.faq-answer p:last-child {
  margin-bottom: 1.5rem;
}

@media (max-width: 768px) {
  .faq-section {
    padding: 4rem 1rem;
  }

  .faq-section h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .faq-question {
    font-size: 1rem;
    padding: 1.25rem;
  }

  .faq-answer {
    padding: 0 1.25rem;
  }
}

/* Mobile Menu Styles */
.mobile-menu-button {
  display: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: transform 0.3s ease;
  width: 32px;
  height: 32px;
}

.mobile-menu-button svg {
  width: 32px;
  height: 32px;
  color: var(--text);
  transition: transform 0.3s ease;
}

/* .mobile-menu-button:hover {
  transform: scale(1.1);
} */

.mobile-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  padding: 1.5rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.mobile-menu.open {
  display: flex;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.mobile-menu .nav-cta {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  min-width: 120px;
  padding: 0.5rem 1rem;
  text-align: center;
  border-radius: 8px;
  font-size: 1.1rem;
}

.mobile-menu .nav-cta.button {
  padding: 0.5rem 1rem;
  background-color: var(--primary);
  color: white;
  margin-bottom: 0.5rem;
}

@media (max-width: 768px) {
  .desktop-nav {
    display: none;
  }

  .mobile-menu-button {
    display: block;
  }
}

/* Mastery Preview Section */
.mastery-preview {
  background: linear-gradient(
    180deg,
    transparent 0%,
    #e6f7f9 10%,
    #e6f7f9 90%,
    transparent 100%
  );
  position: relative;
  padding: 4rem 2rem;
}

.mastery-preview .subtitle {
  color: #00a4bd;
  max-width: 600px;
  margin-bottom: 3rem;
  position: relative;
  z-index: 2;
}

.mastery-preview .feature-icon {
  color: #00a4bd;
}

.mastery-preview .btn-primary {
  background: #00a4bd;
  box-shadow: 0 4px 15px rgba(0, 164, 189, 0.2);
}

.mastery-preview .btn-primary:hover {
  box-shadow: 0 6px 20px rgba(0, 164, 189, 0.3);
}

.mastery-preview .features-grid {
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.mastery-preview .feature-card {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 2rem;
  height: 100%;
}

.mastery-cta {
  margin-top: 3rem;
  text-align: center;
}

@media (max-width: 1200px) {
  .mastery-preview {
    padding: 3rem 1.5rem;
  }

  .mastery-preview .features-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
  }

  .mastery-preview .feature-card {
    padding: 1.5rem;
  }
}

@media (max-width: 968px) {
  .mastery-preview .features-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }
}

@media (max-width: 640px) {
  .mastery-preview {
    padding: 2rem 1rem;
  }

  .mastery-preview .features-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .mastery-preview .subtitle {
    margin-bottom: 2rem;
  }

  .mastery-preview .feature-card {
    padding: 1.5rem;
  }
}

/* Beta Program Section */
.beta-program {
  padding: 4rem 2rem;
  text-align: center;
  background: linear-gradient(
    to right,
    transparent,
    rgba(76, 29, 149, 0.08) 20%,
    rgba(76, 29, 149, 0.08) 80%,
    transparent
  );
  position: relative;
  margin: 0;
}

.beta-program::before,
.beta-program::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 40px;
  pointer-events: none;
}

.beta-program::before {
  top: 0;
  background: linear-gradient(to bottom, white, transparent);
}

.beta-program::after {
  bottom: 0;
  background: linear-gradient(to top, white, transparent);
}

.beta-container {
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  position: relative;
  z-index: 2;
}

.beta-program h2 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #4c1d95;
  margin: 0;
}

.beta-subheading {
  font-size: 1.1rem;
  color: #6d28d9;
  margin: 0.1rem 0 0.5rem;
  font-weight: 500;
}

.beta-button {
  min-width: 160px;
  background-color: #4c1d95 !important;
  box-shadow: 0 4px 12px rgba(76, 29, 149, 0.25) !important;
  transition: all 0.3s ease !important;
}

.beta-button:hover {
  background-color: #5b21b6 !important;
  box-shadow: 0 6px 16px rgba(76, 29, 149, 0.35) !important;
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .beta-program {
    padding: 2rem 1rem;
  }

  .beta-program h2 {
    font-size: 1.75rem;
  }
}
