html {
  scroll-behavior: smooth;
}

/* AI theme styles */
.ai {
  --primary: #435ce8;
  --primary-dark: #3a4ec7;
  --gradient-start: #f0eeff;
  --gradient-mid: #e4e1ff;
  --gradient-end: #ffffff;
  --primary-alt: #ff6b4a;
}

/* Override specific button shadows for primary color */
.ai .btn-primary,
.ai .nav-cta.button {
  box-shadow: 0 4px 15px rgba(67, 92, 232, 0.2);
}

.ai .btn-primary:hover,
.ai .nav-cta.button:hover {
  box-shadow: 0 6px 20px rgba(67, 92, 232, 0.3);
}

/* Override testimonial stars color */
.ai .testimonial-stars {
  color: var(--primary);
}

/* Override FAQ icon color */
.ai .faq-icon {
  color: var(--primary);
}

.faq-answer p:last-child {
  margin-bottom: 1.5rem;
  margin-top: 0;
}

/* Override swiper navigation buttons */
.ai .swiper-button-next,
.ai .swiper-button-prev {
  color: var(--primary) !important;
}

.ai .swiper-pagination-bullet {
  background: var(--primary) !important;
}

.ai .swiper-pagination-bullet-active {
  background: var(--primary) !important;
}

/* Override hero title gradient */
.ai .hero-title {
  background: linear-gradient(135deg, var(--primary), var(--primary-dark));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ai .hero-title .highlight {
  background: none;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: var(--primary-alt);
}

/* Override social links hover color */
.ai .social-link:hover,
.ai .footer-cta:hover,
.ai .footer-links a:hover {
  color: var(--primary);
}

.ai .mobile-menu.open {
  display: flex;
}

.ai-logo {
  max-width: 125px;
}

.hero-ai {
  padding: 8rem 3rem 4rem;
  position: relative;
  background: linear-gradient(
    180deg,
    var(--gradient-start) 0%,
    var(--gradient-start) 70%,
    transparent 100%
  );
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hero-ai::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100px;
  width: 100%;
  pointer-events: none;
  background: linear-gradient(0deg, white 0%, transparent 100%);
}

.hero-ai-container {
  max-width: 1400px;
  width: 100%;
  gap: 2rem;
}

.hero-video {
  max-width: 750px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  background-color: #000;
}

.hero-video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

/* Value proposition section */
.value-proposition {
  background-color: var(--background-alt);
  padding: 6rem 2rem;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.value-proposition h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.value-proposition .subtitle {
  margin-bottom: 3rem;
}

.value-pillars {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.pillar-card {
  background: #fff;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.15s ease-out;
}

.pillar-card:hover {
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
}

.pillar-icon {
  width: 64px;
  height: 64px;
  margin: 0 auto 1rem;
  color: var(--primary);
}

.pillar-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.pillar-subtitle {
  font-size: 1rem;
  margin-top: -0.25rem;
  margin-bottom: 1rem;
  font-weight: 500;
}

/* Feature highlights section */
.feature-highlights {
  padding: 6rem 2rem;
  text-align: center;
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.feature-highlights::before,
.feature-highlights::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 100px;
  width: 100%;
  pointer-events: none;
}

.feature-highlights::before {
  top: 0;
  background: linear-gradient(180deg, white 0%, transparent 100%);
}

.feature-highlights::after {
  bottom: 0;
  background: linear-gradient(0deg, white 0%, transparent 100%);
}

.feature-highlights h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.features-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.feature-card {
  background: white;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05);
  height: 100%;
  transition: box-shadow 0.15s ease-out;
}

.feature-card:hover {
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
}

.feature-icon {
  width: 48px;
  height: 48px;
  color: var(--primary);
  margin-bottom: 1rem;
}

.feature-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

/* Use cases section */
.use-cases {
  padding: 6rem 2rem;
  text-align: center;
  background-color: var(--background-alt);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.use-cases h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.use-cases-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.use-case-card {
  background: white;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.15s ease-out;
}

.use-case-card:hover {
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
}

.use-case-icon {
  width: 48px;
  height: 48px;
  color: var(--primary);
  margin-bottom: 1rem;
}

.use-case-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

/* Testimonials section */
.testimonials {
  padding: 6rem 2rem;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.testimonials h2 {
  margin-bottom: 1rem;
}

.testimonials .subtitle {
  color: var(--primary);
  margin-bottom: 3rem;
}

.testimonials-desktop {
  display: block;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.testimonials-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 24px;
  width: 100%;
}

.testimonials-mobile {
  display: none;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.testimonials-carousel {
  padding: 1rem;
  height: auto !important;
}

.testimonials-carousel .swiper-slide {
  height: auto !important;
  display: flex;
  justify-content: center;
}

.testimonial-card {
  background: white;
  padding: 1.5rem;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.testimonial-stars {
  color: var(--primary);
  display: flex;
  gap: 0.25rem;
  margin-bottom: 1rem;
}

.testimonial-stars svg {
  width: 20px;
  height: 20px;
}

.testimonial-text {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
  flex-grow: 1;
}

.testimonial-author h4 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.testimonial-author p {
  color: #6b7280;
  font-size: 0.9rem;
}

/* Swiper customization */
.testimonials .swiper-button-next,
.testimonials .swiper-button-prev {
  color: var(--primary);
}

.testimonials .swiper-pagination-bullet-active {
  background: var(--primary);
}

.billing-toggle {
  margin-bottom: 0.5rem;
}

@media (max-width: 1024px) {
  .testimonials-desktop {
    display: none;
  }

  .testimonials-mobile {
    display: block;
  }

  .testimonials-carousel {
    padding: 1rem 0;
  }

  .testimonial-card {
    height: 100%;
  }
}

@media (max-width: 768px) {
  .testimonials {
    padding: 4rem 1rem;
  }

  .testimonial-card {
    padding: 1.5rem;
  }

  .testimonial-stars svg {
    width: 16px;
    height: 16px;
  }

  .hero-cta {
    align-items: center !important;
  }

  .hero-content {
    text-align: center !important;
  }

  .hero-cta .btn-primary {
    align-self: center !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .guarantee-text {
    text-align: center !important;
    width: 100% !important;
  }
}

/* Responsive styles */
@media (max-width: 1200px) {
  .hero-video {
    max-width: 600px;
  }

  .hero-ai-container {
    gap: 1rem;
  }

  .hero-content {
    min-width: 45%;
  }

  .value-pillars {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
}

@media (max-width: 968px) {
  .hero-video {
    max-width: 500px;
    margin: 0 auto;
  }

  .hero-ai {
    padding: 6rem 1rem 2rem;
  }
}

@media (max-width: 640px) {
  .hero-video {
    max-width: 340px;
    margin-top: 2rem;
  }

  .hero-ai {
    padding: 5rem 1rem 2rem;
  }

  .value-pillars {
    grid-template-columns: 1fr;
  }

  .subtitle {
    margin-bottom: 2rem;
  }

  .value-proposition h2 {
    font-size: 2rem;
  }

  .feature-highlights h2 {
    font-size: 2rem;
  }

  .use-cases h2 {
    font-size: 2rem;
  }

  .why-now h2 {
    font-size: 2rem;
  }

  .testimonials h2 {
    font-size: 2rem;
  }
}

@media (max-width: 940px) {
  .features-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 640px) {
  .features-list {
    grid-template-columns: 1fr;
  }
}

/* Final CTA Section */
.final-cta {
  background: linear-gradient(135deg, var(--primary), var(--primary-dark));
  color: white;
  padding: 6rem 2rem;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.final-cta .cta-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  text-align: center;
  margin: 0 auto;
}

.final-cta .cta-content h2 {
  margin-bottom: 1.5rem;
  font-size: 2.5rem;
}

.final-cta .btn-primary {
  margin-top: 1.5rem;
}

.hero-cta {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.hero-cta .btn-primary {
  align-self: flex-start;
}

.guarantee-text {
  margin-top: 0;
  font-size: 0.9rem;
  opacity: 0.8;
}

.why-now {
  padding: 6rem 2rem;
  text-align: center;
  background-color: var(--background-alt);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.trusted-text {
  margin-top: 2rem;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 500;
  opacity: 0.9;
}

.why-now .trusted-by {
  margin-top: 3rem;
}

.popular-tag {
  position: absolute;
  top: -12px;
  right: 20px;
  background-color: var(--primary);
  color: white;
  padding: 4px 12px;
  border-radius: 15px;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
}

.pricing-card.business {
  position: relative;
}

.pricing-guarantee {
  text-align: center;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.5rem;
}

/* Video styles */
.video-container {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: #000;
  display: block;
}

.video-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: none;
  background-color: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(4px);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 5;
  opacity: 1;
  visibility: visible;
}

.video-play-button.hidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.1s ease, visibility 0.1s ease;
}

.video-play-button svg {
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.3));
  cursor: pointer;
}

.video-play-button svg path {
  cursor: pointer;
}

.video-play-button:hover {
  background-color: rgba(255, 255, 255, 0.4);
  transform: translate(-50%, -50%) scale(1.1);
}

.alt-main-heading-color {
  color: #ffa116;
}

.nav-cta.reading-button {
  color: #ffa116;
  font-weight: 600;
}

.nav-cta.reading-button:hover {
  color: #e85a2c;
}

/* AI Models Section */
.ai-models {
  padding: 3rem 2rem 5rem;
  text-align: center;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ai-models-container {
  max-width: 1200px;
  margin: 0 auto;
}

.ai-models h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  line-height: 1.2;
}

.ai-models .subtitle {
  font-size: 1.25rem;
  max-width: 800px;
  margin: 0 auto 3rem;
}

.models-image {
  max-width: 850px;
  margin: 0 auto;
}

.models-image img {
  width: 100%;
  height: auto;
  display: block;
}

@media (max-width: 768px) {
  .ai-models {
    padding: 8rem 1rem 6rem;
  }

  .ai-models h2 {
    font-size: 1.8rem;
  }

  .ai-models .subtitle {
    font-size: 1.1rem;
    margin-bottom: 2rem;
  }
}
